import React, { useState, useEffect } from 'react';
import { Link, navigate } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import "styles/dashboard.style.css"
import DashboardContent from "components/medangle/DashboardContent"

function IndexPage(props){
    return(
        
        <Layout>
            <Seo title="Home" />
            <div>
              
            <DashboardContent/>
            </div>
        </Layout>
      
        

    );
}
export default IndexPage